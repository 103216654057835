import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./404.css"

const NotFoundPage = () => (
  <Layout footerVariant="black">
    <SEO title="404: Not found" />
    <div className="not-found-page header-fixed-adjust">
      <div className="container">
        <h1 className="title">NOT FOUND</h1>
        <p>Woops! Looks like you're trying to go somewhere impossible.</p>
        <p>Apologies.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
